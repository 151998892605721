/* global */
/*-----------------------------------------------------------------------------*/
html {
  overflow-y: scroll;
}
body {
  min-width: 320px;
}
a:focus {
  outline: none !important;
}
a:hover {
  text-decoration: none;
}
ul {
  list-style: none;
  padding-left: 0;
}
.select2-container {
  width: 100%;
}
.no-margin {
  margin: 0;
}
.no-padding {
  padding: 0;
}
.no-wrap {
  white-space: nowrap;
}
.break-word {
  word-wrap: break-word;
}
.tl {
  text-align: left !important;
}
.tr {
  text-align: right !important;
}
.wrap {
  overflow: hidden;
}
/* Colors */
/*-----------------------------------------------------------------------------*/
.c-gray {
  color: #555555;
}
.c-gray-dark {
  color: #333333;
}
.c-gray-darker {
  color: #222222;
}
.c-gray-light {
  color: #777777;
}
.c-gray-lighter {
  color: #eeeeee;
}
.c-highlight {
  color: #0f5b92;
}
/* Fonts */
/*-----------------------------------------------------------------------------*/
.f-small {
  font-size: 12px;
}
.f-normal {
  font-size: 14px;
}
/* Forms */
/*-----------------------------------------------------------------------------*/
input[type=text],
input[type=email] {
  height: 26px;
  padding: 3px;
}
form table label {
  font-size: 12px;
}
form select {
  width: 100%;
}
form textarea {
  resize: vertical;
}
form fieldset {
  padding: 8px;
  border: 1px solid #E0E0E0;
  min-height: 45px;
  border-bottom-width: 0;
}
form fieldset.last {
  border-bottom: 1px solid #E0E0E0;
}
form fieldset.gray {
  background-color: #f9f9f9;
}
/* Table */
/*-----------------------------------------------------------------------------*/
table label {
  font-size: 12px;
}
/* Box */
/*-----------------------------------------------------------------------------*/
.box-border {
  border: 1px solid #e1e1e1;
  padding: 20px;
}
.box-section {
  padding: 10px;
  line-height: 40px;
  margin: 10px 0;
  background-color: #f4f4f4;
  border: 1px solid #e2e2e2;
  border-bottom-color: #d3d3d3;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  position: relative;
}
.box-section .btn {
  margin-top: 7px;
}
/* Table Sorting */
/*-----------------------------------------------------------------------------*/
.tablesorter .tablesorter-header {
  cursor: pointer;
  color: #0f5b92;
}
.tablesorter .tablesorter-header span {
  display: none;
  margin-top: 3px;
}
.tablesorter .tablesorter-headerUnSorted {
  color: #555555;
}
.tablesorter .tablesorter-headerDesc .up {
  display: block;
}
.tablesorter .tablesorter-headerAsc .down {
  display: block;
}
/*# sourceMappingURL=_/css/main.css.map */